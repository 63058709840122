@use 'vars' as *;
.t-blog {
    .btn-gradient-outline {
        z-index: unset;
    }
    .card-highlight {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 2rem;
        margin-bottom: 8rem;
        .section {
            font-weight: bold;
            color: $violet;
        }
        .content {
            display: flex;
            flex-direction: column;
            max-width: 400px;
            .title {
                color: $blue;
                font-size: 48px;
                font-weight: bold;
                margin-top: 2rem;
                @media screen and (max-width: 450px) {
                    font-size: 6vw;
                }
                @media screen and (max-width:300px) {
                    font-size: 24px;
                }
            }
            .summary {
                color: $dark-grey;
                margin: 2rem 0;
            }
        }
        .img-wrapper {
            .section {
                display: none;
            }
            img {
                height: 100%!important;
                width: 100%;
                object-fit: cover;
            }
        }
        @media screen and (max-width:960px) {
            display: flex;
            flex-direction: column-reverse;
            max-width: 780px;
            margin: 0 auto;
            margin-bottom: 8rem;
            .img-wrapper {
                .section {
                    display: block;
                    margin-bottom: 2rem;
                }
            }
            .content {
                max-width: none;
                .section {
                    display: none;
                }
            }
        }
    }
    .card {
        display: flex;
        flex-direction: column;
        width: 100%;
        img {
            height: 100%!important;
            object-fit: cover;
        }
        // margin: 1rem auto;
        .title {
            color: $blue;
            font-size: 36px;
            font-weight: bold;
            margin-top: 1rem;
            @media screen and (max-width: 450px) {
                font-size: 5vw;
            }
            @media screen and (max-width:300px) {
                font-size: 18px;
            }
        }
        .summary {
            color: $dark-grey;
            margin: 2rem 0;
        }
        a {
            margin-top: auto;
            margin-bottom: 2rem;
            @media screen and (max-width: 450px) {
                font-size: 3.5vw;
            }
            @media screen and (max-width:300px) {
                font-size: 14px;
            }
        }
    }
    .grid-layout {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 350px));
        grid-gap: 2rem;
        justify-content: space-between;
        @media screen and (max-width: 1200px) {
            justify-content: space-evenly;
        }
    }
    .more {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;
        a {
            margin: 0 1rem;
        }
    }
    .blog-item {
        padding-bottom: 4rem;
        .full-width-img {
            width: 100%;
            height: 40%;
            @media screen and (max-width: 750px) {
                width: 100vw;
                height: 40%;
                margin: 0 -20px;
                margin-top: -20px;
            }
            img {
                height: 100%!important;
                width: 100%;
                object-fit: cover;
            }
        }
        .title {
            margin: 3rem 0;
            font-weight: bold;
            font-size: 48px;
            color: $blue;
            @media screen and (max-width: 750px) {
                font-size: 6vw;
            }
            @media screen and (max-width:450px) {
                font-size: 24px;
            }
        }
        hr {
            margin: 3rem 0;
        }
        .section {
            color: $violet;
            font-weight: bold;
        }
        .grid-layout {
            margin: 4rem 0;
            .title {
                font-size: 36px;
                margin-bottom: 0;
                @media screen and (max-width: 960px) {
                    font-size: 5vw;
                }
                @media screen and (max-width:450px) {
                    font-size: 18px;
                }
            }
        }
    }
    .footer-container {
        background: linear-gradient( 270deg, #ce62a5 -10%, #772E8F 10%, #21BEC6 110%);
        overflow: hidden;
        position: relative;
        .footer-bottom {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

@media only screen and (max-width: 960px) {
    .t-blog .card-highlight {
        margin-top: 0px;
    }
}