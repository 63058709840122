@use 'vars' as *;
@use 'blog';
@use 'resource';
@use 'index';
@import url('~@splidejs/splide/dist/css/splide.min.css');
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.site-link {
    filter: brightness(1);
    transition: all .5s linear;
    &:hover {
        filter: grayscale(1) invert(1);
    }
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

input,
select,
textarea,
button {
    border-radius: 0;
    /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.contentwrap {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 20px;
}

header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border: none;
}

a.logo {
    display: inline-block;
    width: 122px;
    height: auto;
}

.img-circle {
    img {
        border-radius: 50%;
    }
}


/**************************
    Common styles
**************************/


/** menu generic **/

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

nav li {
    display: inline-block;
    box-sizing: border-box;
}

nav a {
    display: block;
    color: inherit;
    text-decoration: none;
}

header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}


/** form generic **/

form {
    width: 100%;
    box-sizing: border-box;
}

form p {
    position: relative;
    margin: 5px 0;
}

label.float,
input {
    height: 40px;
    line-height: 40px;
}

label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}

input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font-primary;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
}

textarea {
    min-height: 80px;
}

label.float {
    position: absolute;
    left: 0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}

.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    // left: 5px;
    // padding-left: 0;
    // line-height: 1;
    margin-top: -10px;
    font-size: 0.75em;
    transition: all 1s ease;
}

form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}


/** site links **/

.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}


/*============ header ============*/

header.top {
    font-family: $font-primary;
    font-size: 14px;
}

.header-bar-top {
    color: $violet;
}

header.top ul {
    z-index: 1;
    width: calc(100%);
    margin-left: 10%;
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    a{
        text-shadow: 0 0 2px #fff;
    }
}

// header.top ul > li {
//     margin: 0 1.5vw
// }
.header-menu {
    max-width: 1900px;
    display: flex;
    justify-content: space-between;
    padding: 0 3vw;
    align-items: center;
    font-weight: bold;
    z-index: 1;
    position: relative;
}

nav {
    a span.btn {
        margin: 0 -.5vw;
    }
}

.header-menu ul {
    text-align: justify;
    line-height: 0;
}

.header-menu ul::after {
    content: "";
    display: inline-block;
    width: 100%;
    line-height: 0px;
    vertical-align: top;
}

.header-menu li {
    margin: 5px;
    line-height: 1;
}

.header-menu li.depth-0 {
    display: flex;
    align-items: center;
}

.header-menu a {
    padding: 15px 0;
}

.header-menuli>ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #f6f6f6;
    color: #082133;
    z-index: 10000;
    text-align: left;
    display: none;
}

.header-menu li:hover>ul {
    display: block;
}

.header-menu li>ul::before,
.header-menu li>ul::after {
    content: "";
    background: #f6f6f6;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100%;
}

.header-menu li>ul::before {
    left: 0;
    padding-left: 50vw;
    margin-left: -50vw;
    background: #f6f6f6;
}

.header-menu li>ul::after {
    right: 0;
    padding-right: 50vw;
    margin-right: -50vw;
    background: #f6f6f6;
}

.header-menu .depth-1 .title {
    font-size: 18px;
}

.header-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}

#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index: 100;
}

#mobile-menu>span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #000;
    margin: 6px 0;
    transition: all 0.5s ease;
}

.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.slide-menu-active #mobile-menu span.three {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
}

.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}


/***** Slide menu ****/

.slide-menu {
    display: none;
}

.slide-menu {
    background: $site-gradient-dark;
    width: 240px;
    z-index: 1000;
    position: fixed;
    height: 100%;
    top: 0;
    right: -240px;
    overflow-y: scroll;
}

.slide-menu-active .slide-menu {
    right: 0px;
}

.site-content-menu-slide {
    overflow: hidden;
    position: relative;
    right: 0;
}


/* transition for menu slide */

.slide-menu,
.site-content-menu-slide,
#mobile-menu {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.slide-menu .menu-title {
    padding: 1em;
    color: $violet;
    background: $white;
    margin: 0;
    font-weight: bold;
    display: none;
}

.slide-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.slide-menu ul ul {
    background: $site-gradient-light;
}

.slide-menu li {
    position: relative;
    display: block;
}

.slide-menu li>ul {
    display: none;
}

.slide-menu li.expand-active>ul {
    display: block;
}

.slide-menu a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    border-bottom: 1px solid #030b16;
    padding: 18px;
    text-decoration: none;
}

.slide-menu li.has-sub {
    position: relative;
    display: block;
}

.slide-menu li.has-sub>a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px;
    /* space for drop */
}

.slide-menu a:hover {
    background: $violet;
}

.slide-menu li.has-sub>a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px;
    /* border bottom */
    z-index: 100;
}

.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}

.slide-menu ul .expand i {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.expand-active>.expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}


/*============ footer ============*/

footer .footer-bottom {
    color: #fff;
}

footer {
    .footer-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-around;
        margin: 0 3rem;
        font-weight: bold;
        align-items: center;
        z-index: 10;
        &>* {
            margin-bottom: 2rem;
        }
        .logo {
            filter: brightness(0) invert(1);
        }
        .site-links {
            margin-left: 4rem;
            @media screen and (max-width: 1300px) {
                margin-left: 0;
                order: 1;
            }
        }
        nav {
            flex-grow: 2;
            @media screen and (max-width: 1300px) {
                width: 100%;
            }
            ul {
                padding: 0 2rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                li {
                    margin: 1rem;
                }
                @media screen and (max-width: 900px) {
                    justify-content: center;
                    padding: 0;
                    li {
                        a {
                            margin-bottom: 1rem;
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
                @media screen and (max-width: 600px) {
                    flex-direction: column;
                    li {
                        margin: 0;
                    }
                }
            }
        }
    }
}


/** bykeo ***/

.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 3rem;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    position: relative;
}

.bykeo a {
    color: $grey;
    text-decoration: none;
}

.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.bykeo a:hover img {
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ sub page============*/

.page-graphic {
    position: relative;
    display: block;
    width: 100%;
    height: 30%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

.page-title {
    color: $blue;
}


/*============ Contact ============*/

// .footer-container {
//     overflow: hidden;
// }
.contact-container {
    // @include skew-element;
    width: 100%;
    position: relative;
    z-index: 10;
    // margin-top: -35rem;
    // padding-top: 35rem;
    // background: $site-gradient-reverse!important;
}

.footer-container {
    .footer-bottom {
        background: $site-gradient-reverse;
        margin-top: -40rem;
        padding-top: 45rem;
        padding-bottom: 3rem;
    }
    &:after {
        content: "";
        width: 0;
        height: 0;
        border-bottom: 60vw solid rgba(0, 0, 0, .3);
        border-left: 100vw solid transparent;
        z-index: 100;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        /*
        This seems to trigger a layout issue.
        https://app.proworkflow.com/KEODesign/?fuseaction=jobs&fusesubaction=jobdetails&Jobs_currentJobID=3418&taskID=43047
        @media screen and (min-width: 1600px) {
            transform: translate(10vw, 10vw);
        }
        */
    }
}

.contact-container {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        color: $white;
        grid-gap: 1rem;
        .content {
            display: flex;
            flex-direction: column;
            padding: 2rem;
            padding-left: 0px;
            /* align left */
            @media screen and (max-width: 960px) {
                padding-left: 2rem;
            }
            @media screen and (max-width: 450px) {
                padding: 0;
                padding-top: 4rem;
            }
            .section {
                color: $violet;
                font-weight: bold;
                margin-bottom: 2rem;
            }
            .title {
                font-size: 48px;
                font-weight: bold;
                color: $blue;
                @media screen and (max-width: 960px) {
                    font-size: 5vw;
                }
                @media screen and (max-width:450px) {
                    font-size: 24px;
                }
            }
            a {
                z-index: 0;
            }
            .contact-info {
                margin-top: 1rem;
                color: $indigo;
                font-weight: bold;
                .item {
                    margin-top: 0;
                    margin-bottom: 5px;
                }
                a {
                    color: inherit;
                    text-decoration: none;
                }
                .site-links {
                    margin-top: 1rem;
                    .site-link:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .contact-form {
            color: $grey;
            padding-top: 6rem;
            input,
            textarea {
                border-radius: 20px;
                border: 2px solid transparent;
                margin-bottom: .75rem;
                outline: none;
            }
            button {
                float: right;
                margin-left: 10px;
            }
            .recaptcha-notice {
                color: $white;
                a {
                    color: inherit;
                }
            }
            .form-result-wrapper {
                color: $white;
            }
        }
        @media screen and (max-width:960px) {
            grid-template-columns: 1fr;
            .contact-form {
                padding: 2rem;
            }
        }
        @media screen and (max-width:450px) {
            .contact-form {
                padding: 0;
            }
        }
    }
}

.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 960px) {
    #mobile-menu {
        display: block;
    }
    .header-menu ul {
        display: none !important;
    }
    .slide-menu {
        display: block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    /** form **/
    .contact-details.has-map,
    .map-wrap {
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}


/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}


/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}