@use 'vars' as *;
.t-home {
    background: $site-gradient-reverse;
    main {
        padding-bottom: 15rem;
        @media screen and (max-width: 960px) {
            padding-bottom: 0;
        }
    }
    .intro {
        background: url('../assets/line-bg.png');
        padding-left: 15vw;
        padding-right: 15vw;
        padding-bottom: 10rem;
        background-color: white;
        position: relative;
        z-index: 0;
        margin-top: -10rem;
        min-height: 80vh;
        height: 100vw;
        max-height: 120vh;
        display: flex;
        margin-top: -10rem;
        align-items: center;
        &>div {
            display: flex;
            flex-direction: column;
            max-width: 500px;
        }
        h1 {
            font-size: 48px;
        }
        // @media only screen and (max-width: 960px) {
        //     padding-top: 10rem;
        //     padding-bottom: 35vw;
        // }
        @media screen and (max-width:760px) {
            padding-bottom: 5rem;
            h1 {
                font-size: 7vw;
            }
            a {
                padding: 2vw 3vw;
                font-size: 2.5vw;
            }
        }
        @media screen and (max-width:350px) {
            // padding-bottom: 50vw;
            padding-top: 1rem;
            h1 {
                font-size: 24px;
            }
            a {
                font-size: 14px;
            }
        }
        @media screen and (max-height:660px) {
            max-height: 200vh;
            min-height: 100vh;
            padding-top: 1rem;
        }
    }
    .method-container {
        width: 100%;
        padding: 5rem 0;
        margin-top: -30vw;
        // margin-top: -33rem;
        /* -35vw; */
        background: $site-gradient-reverse;
        @include skew-element;
        #method {
            z-index: 100;
            position: relative;
            max-width: 250px;
            margin-left: 15vw;
            z-index: 100;
            padding-top: 4rem;
            padding-bottom: 8rem;
            @media screen and (max-width: 550px) {
                text-align: center;
                max-width: unset;
            }
            .section {
                color: $violet;
                font-weight: bold;
                font-size: 18px;
            }
            .title {
                font-size: 48px;
                color: $white;
            }
            .description {
                color: $white;
                margin-bottom: 4rem;
                line-height: 1.5;
            }
            a {
                text-decoration: none;
            }
            @media only screen and (max-width: 960px) {
                margin-top: -3rem;
                .title {
                    font-size: 4vw;
                }
                margin-right: 15vw;
                padding-bottom: 6rem;
            }
            @media only screen and (max-width: 700px) {
                .title {
                    font-size: 14px;
                }
                padding-bottom: 4rem;
            }
            @media only screen and (max-width: 350px) {
                .title {
                    font-size: 14px;
                }
            }
        }
        .model-wrapper {
            position: absolute;
            bottom: -3vw;
            right: 15vw;
            width: 60vw;
            max-width: 1000px;
            @media only screen and (max-width: 1400px) {
                right: 5vw;
                bottom: 3vw;
            }
            @media only screen and (max-width: 960px) {
                bottom: 10vw;
                right: 0;
            }
            @media only screen and (max-width: 750px) {
                bottom: 17vw;
            }
            @media only screen and (max-width: 550px) {
                display: none;
            }
        }
        @media screen and (max-width:700px) {
            // margin-top: -33rem;
        }
    }
    .method-list-container {
        background: $site-gradient-dark-reverse;
        height: auto;
        z-index: 100;
        position: relative;
        @include skew-element;
        margin-top: -200px;
        padding: 10rem 5rem;
        .banners {
            width: 100%;
            @media screen and (max-width: 550px) {
                text-align: center;
            }
            .banner-stage {
                @include skew-element;
                display: grid;
                grid-gap: 1rem 2rem;
                // margin-bottom: 20vw;
                grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                justify-content: center;
                .banner-content {
                    &:hover .banner-inner-image {
                        transform: scale(1.2);
                    }
                }
                .banner-inner-image {
                    margin-bottom: 1rem;
                    transition: all .2s ease-in-out;
                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 0;
                    }
                }
                .banner-name {
                    display: block;
                    margin-bottom: 1rem;
                    font-weight: bold;
                    font-size: 1.5rem;
                    // margin-left: 2rem;
                    color: $blue;
                    @media only screen and (max-width: 656px) {
                        margin-left: 0;
                    }
                }
                .banner-caption {
                    margin-left: 2rem;
                    color: $white;
                    display: block;
                    margin-left: 0;
                    @media only screen and (max-width: 656px) {
                        margin-left: 0;
                    }
                }
            }
        }
        @media only screen and (max-width: 960px) {
            padding: 5rem;
            padding-bottom: 10rem;
        }
        // @media only screen and (max-width: 750px) {}
        @media only screen and (max-width: 550px) {
            padding: 3rem;
            padding-bottom: 8rem;
        }
    }
    .solution-container {
        @include skew-element;
        background: $white;
        height: auto;
        z-index: 200;
        position: relative;
        padding: 5rem 0rem;
        padding-bottom: 0;
        display: flex;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            padding: 3rem;
        }
        @media screen and (max-width: 500px) {
            padding: 1rem;
        }
        .wrapper {
            max-width: 250px;
            margin-left: 15vw;
            z-index: 100;
            padding-top: 4rem;
            padding-bottom: 5rem;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            @media screen and (max-width: 1100px) {
                max-width: unset;
                padding-bottom: 0;
                margin-right: 15vw;
                padding-top: 15vw;
            }
            @media screen and (max-width: 500px) {
                padding-top: 25vw;
                margin: 0;
            }
            .section {
                color: $violet;
                font-weight: bold;
                font-size: 18px;
            }
            .title {
                font-size: 48px;
                color: $blue;
                margin: 1rem 0;
                @media screen and (max-width: 900px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 500px) {
                    font-size: 24px;
                }
            }
            .description {
                margin-top: 2rem;
                margin-bottom: 4rem;
                line-height: 1.5;
                position: relative;
                z-index: 0;
                transform: skew(-.001deg);
                @media screen and (max-width: 1100px) {
                    margin-bottom: 0;
                }
            }
            .clickable {
                display: inline-block;
                cursor: pointer;
                margin-top: 1rem;
                font-weight: bold;
            }
        }
        .model-wrapper {
            position: absolute;
            top: -10vw;
            right: -2vw;
            bottom: 0;
            width: 60vw;
            max-width: 1000px;
            img {
                object-fit: contain;
                width: 100%;
                height: 100%!important;
            }
            @media screen and (max-width: 1100px) {
                display: none;
                top: -15vw;
            }
        }
        .table-wrapper {
            width: 100%;
            border-radius: 20px;
            background-color: #fff;
            padding: 2rem;
            box-shadow: 0px 0px 25px -5px;
            z-index: 200;
            margin: 0 4vw;
            margin-top: 20vw;
            margin-bottom: -17rem;
            min-width: 240px;
            overflow-y: hidden;
            @media screen and (max-width: 1100px) {
                // width: 90vw;
                margin: 0 auto;
                margin-top: 2rem;
                margin-bottom: -10rem;
            }
            @media screen and (max-width:700px) {
                font-size: 2.75vw;
            }
            .table {
                display: grid;
                grid-template-columns: 1fr .5fr .5fr .5fr;
                padding: 0;
                .package {
                    padding-left: 10px;
                }
                .heading {
                    cursor: pointer;
                }
                &.one {
                    .heading:nth-child(4n+2) {
                        border-top: 3px solid $violet;
                        span {
                            background: $site-gradient;
                            outline: none;
                            border: none;
                            filter: hue-rotate(0deg);
                            &:hover {
                                filter: hue-rotate(25deg);
                                color: white;
                            }
                        }
                    }
                    div:nth-child(4n+2) {
                        border-left: 3px solid $violet;
                        border-right: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                    div:nth-last-child(-4n+3) {
                        border-bottom: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                }
                &.two {
                    .heading:nth-child(4n+3) {
                        border-top: 3px solid $violet;
                        span {
                            background: $site-gradient;
                            outline: none;
                            border: none;
                            filter: hue-rotate(0deg);
                            &:hover {
                                filter: hue-rotate(25deg);
                                color: white;
                            }
                        }
                    }
                    div:nth-child(4n+3) {
                        border-left: 3px solid $violet;
                        border-right: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                    div:nth-last-child(-4n+2) {
                        border-bottom: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                }
                &.three {
                    .heading:nth-child(4n+4) {
                        border-top: 3px solid $violet;
                        span {
                            background: $site-gradient;
                            outline: none;
                            border: none;
                            filter: hue-rotate(0deg);
                            &:hover {
                                filter: hue-rotate(25deg);
                                color: white;
                            }
                        }
                    }
                    div:nth-child(4n+4) {
                        border-left: 3px solid $violet;
                        border-right: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                    div:nth-last-child(-4n+1) {
                        border-bottom: 3px solid $blue;
                        .check {
                            content: url('../assets/check-gradient.svg');
                            height: 30px;
                            @media screen and (max-width: 500px) {
                                height: 7vw;
                            }
                        }
                    }
                }
            }
            .submessage {
                position: absolute;
                bottom: -15%;
                left: 10px;
            }
            .title {
                font-weight: bold;
                color: $blue;
                font-size: 1.5rem;
                margin-right: auto;
            }
            .heading {
                padding: 1.4rem 0;
                &:nth-child(2) {
                    border-top-left-radius: 20px;
                }
                &:nth-child(4) {
                    border-top-right-radius: 20px;
                }
                text-overflow: clip;
                text-align: center;
                @media only screen and (max-width: 1400px) {
                    padding: 0 5px;
                    background-color: transparent!important;
                    white-space: nowrap;
                }
                @media only screen and (max-width: 700px) {
                    span {
                        background: transparent!important;
                        color: $blue;
                        padding: 0;
                        font-weight: bold;
                        border: none;
                    }
                }
            }
            .package {
                justify-content: left;
                .btn-gradient,
                .btn-blue {
                    white-space: nowrap;
                    margin-left: 1rem;
                    padding: 5px 10px;
                    font-weight: 500;
                }
            }
            div {
                padding: 1rem 0;
                overflow: visible;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: wrap;
                height: 100%;
                &:nth-child(8n+9),
                &:nth-child(8n+2),
                &:nth-child(8n+3),
                &:nth-child(8n+4) {
                    background-color: #F2F2F2;
                }
            }
            .empty {
                height: 1px;
                width: 20px;
                border-bottom: 1px solid $grey;
            }
            .check {
                content: url('../assets/check.svg');
                height: 30px;
                @media screen and (max-width: 500px) {
                    height: 7vw
                }
            }
            .check-gradient {
                content: url('../assets/check-gradient.svg');
                height: 30px;
                @media screen and (max-width: 500px) {
                    height: 7vw;
                }
            }
            @media screen and (max-width:500px) {
                font-size: 2.5vw;
                padding: 0;
                margin: 0;
                box-shadow: none;
                border-radius: 0;
                .package {
                    padding-left: 10px;
                    font-size: 10px;
                }
                .heading,
                .title {
                    font-size: 10px;
                }
            }
        }
    }
    .about-container {
        @include skew-element;
        width: 100%;
        position: relative;
        padding-top: 15vw;
        background: $site-gradient-reverse;
        z-index: 100;
        .wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            color: $white;
            grid-gap: 1rem;
            min-height: 250px;
            .image-wrapper {
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    object-fit: cover;
                    width: 100%;
                    border-radius: 50%;
                }
            }
            .about-intro {
                display: flex;
                flex-direction: column;
                .section {
                    color: $violet;
                    font-weight: bold;
                    font-size: 18px;
                }
                .title {
                    font-size: 48px;
                    @media screen and (max-width: 900px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 24px;
                    }
                }
                .summary {
                    line-height: 1.5;
                }
                button {
                    margin-top: auto;
                }
            }
        }
        @media screen and (max-width:960px) {
            padding-top: 18vw;
            margin-bottom: -7rem;
            // padding-bottom: 25rem;
            .wrapper {
                grid-template-columns: 1fr 1fr;
                .about-intro a {
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }
            .description {
                grid-column: 1/-1;
            }
        }
        @media screen and (max-width:700px) {
            .wrapper {
                grid-template-columns: 1fr;
            }
            padding-top: 10rem;
        }
        @media screen and (max-width:400px) {
            margin-bottom: -10rem;
        }
    }
    .coach-container {
        position: relative;
        background: $site-gradient-dark-reverse;
        color: $white;
        .section {
            font-weight: bold;
        }
        .title {
            font-size: 48px;
            font-weight: bold;
            color: $blue;
            width: 65vw;
            max-width: 700px;
            @media screen and (max-width: 900px) {
                font-size: 40px;
            }
            @media screen and (max-width: 320px) {
                font-size: 28px;
            }
        }
        @include skew-element;
        padding-top:22rem;
        padding-bottom:10rem;
        @media screen and (max-width:600px) {
            padding-top: 20rem;
            padding-bottom: 5rem;
        }
        @media screen and (max-width:520px) {
            .coach-list {
                text-align: center;
            }
        }
        .coach-list {
            @include skew-element;
            padding-top: 5rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            justify-content: center;
            grid-gap: 4rem;
            .coach-name {
                color: $blue;
                font-size: 36px;
                font-weight: bold;
            }
            .coach-title {
                font-weight: bold;
            }
        }
    }
    .result-container {
        @include skew-element;
        padding-top: 10rem;
        padding-bottom: 10rem;
        position: relative;
        min-height: 700px;
        background: $site-gradient-reverse;
        z-index: 1;
        @media screen and (max-width: 900px) {
            padding-bottom: 8rem;
        }
        @media screen and (max-width: 550px) {
            padding-bottom: 0;
        }
        .wrapper {
            display: flex;
            gap: 2rem;
            margin: 1rem auto;
            min-height: 600px;
            &.expand {
                flex-direction: column;
                & > * {
                    width: 100%;
                }
                .splide,
                .splide__list,
                .splide__track,
                .splide__list>li {
                    max-width: 60vw;
                    @media screen and (max-width: 900px) {
                        max-width: unset;
                        width: 80vw;
                    }
                }
            }
            &>* {
                width: 50%;
            }
            .card {
                border-radius: 20px;
                background-color: white;
                overflow: hidden;
                height: 100%;
                .card-content {
                    display: flex;
                    flex-direction: column;
                    padding: 2rem 4rem;
                    @media screen and (max-width: 900px) {
                        padding: 2rem 1.5rem;
                    }
                    @media screen and (max-width: 400px) {
                        padding: 5rem 2rem;
                        padding-bottom: 5rem;
                    }
                    .btn-wrapper {
                        margin-top: 2rem;
                    }
                    a {
                        text-decoration: none;
                        font-weight: bold;
                        margin-top: auto;
                        margin-bottom: 40px;
                        @media screen and (max-width: 450px) {
                            font-size: 3vw;
                        }
                        @media screen and (max-width:300px) {
                            font-size: 12px;
                        }
                    }
                }
            }
            .section {
                font-weight: bold;
                position: absolute;
                z-index: 1;
                color: white;
                left: 3rem;
                top: 3rem;
            }
            .splide,
            .splide__list,
            .splide__track,
            .splide__list>li {
                height: 100%;
                max-width: 50vw;
                @media screen and (max-width: 900px) {
                    max-width: unset;
                    width: 80vw;
                }
            }
            .splide {
                margin: 0 auto;
            }
            .splide__arrow {
                display: none;
            }
            .splide__pagination {
                bottom: 2rem;
                justify-content: flex-end;
                padding-right: 1rem;
                li {
                    margin-right: 10px;
                }
                * {
                    box-sizing: border-box;
                }
            }
            .splide__pagination__page {
                height: 12px;
                width: 12px;
                border: 1px solid $grey;
                opacity: 1;
                margin: 0;
                background: #fff;
                &.is-active {
                    transform: unset;
                    background: $grey;
                }
            }
            .result {
                .card {
                    display: flex;
                    flex-direction: column;
                    .card-content {
                        height: 100%;
                        .btn-wrapper {
                            margin-top: auto;
                            padding-top: 2rem;
                            position: relative;
                            z-index: 0;
                        }
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            .blogs {
                img {
                    width: 100%;
                    height: 100%!important;
                    object-fit: cover;
                    // min-height: 100vw;
                    filter: brightness(0.75);
                    @media screen and (max-width: 400px) {
                        min-height: 400px;
                    }
                }
                .card {
                    background-color: transparent;
                }
                .card-content {
                    position: absolute;
                    top: 40px;
                    bottom: 0;
                    left: 0;
                    h2 {
                        color: $white;
                        font-size: 2rem;
                        @media screen and (max-width: 900px) {
                            font-size: 5vw;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:900px) {
            .wrapper {
                display: flex;
                flex-direction: column;
                & >* {
                    width: 100%;
                }
            }
        }
        @media screen and (max-width:550px) {
            .wrapper {
                margin-bottom: 8rem;
            }
        }
        // @media screen and (max-width:350px) {
        //     .wrapper {
        //         margin-bottom: 16rem;
        //     }
        // }
    }
    .shop-graphic {
        @include skew-element;
        overflow: hidden;
        height: 700px;
        background-color: $dirty-white;
        img {
            width: 100%;
            margin-top: -25vw;
            object-fit: cover;
        }
        @media screen and (max-width:960px) {
            img {
                height: 120%!important;
                margin-top: -30vw;
            }
        }
    }
    .shop-container {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 100;
        .wrapper {
            // position: absolute;
            margin-top: -30rem;
            // @media screen and (max-width: 960px) {
            //     margin-top: -45rem;
            // }
            .card {
                background-color: $white;
                box-shadow: 0 0 25px -10px $grey;
                border-radius: 15px;
                display: grid;
                grid-template-columns: .7fr 1.3fr;
                color: $grey;
                grid-gap: 1rem;
                overflow: hidden;
                img {
                    height: 100%!important;
                    object-fit: cover;
                }
                @media screen and (max-width:960px) {
                    display: flex;
                    flex-direction: column-reverse;
                    img {
                        height: 40vw!important;
                        width: 100%;
                    }
                }
                @media screen and (max-width:900px) {
                    width: 80vw;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                @media screen and (max-width: 900px) {
                    padding: 1rem 1.5rem;
                }
                .section {
                    color: $violet;
                    font-weight: bold;
                    margin-bottom: 2rem;
                }
                .title {
                    font-size: 48px;
                    font-weight: bold;
                    color: $blue;
                    @media screen and (max-width: 960px) {
                        font-size: 3vw;
                    }
                    @media screen and (max-width:300px) {
                        font-size: 18px;
                    }
                }
                .description {
                    position: relative;
                    z-index: 0;
                    @media screen and (max-width: 450px) {
                        a {
                            font-size: 3vw;
                        }
                    }
                    @media screen and (max-width:300px) {
                        a {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
    .contact-container {
     
        width: 100%;
        position: relative;
        margin-top: 0;
    }

    // Overide default footer style
    .footer-bottom {
        // background: $site-gradient-dark-reverse;
        margin-top: -40rem;
        padding-top: 45rem;
        padding-bottom: 3rem;
    }
    // .footer-container {
    //     overflow: hidden;
    // }
}