@use 'vars' as *;

.t-resource{
    #download-form{
        color: $grey;
        padding-top: 3rem;
        input,
        textarea {
            border-radius: 20px;
            border: 2px solid $violet;
            margin-bottom: .75rem;
            outline: none;
        }
        button {
            float: right;
            margin-left: 10px;
        }
        .recaptcha-notice {
            color: $white;
            a {
                color: inherit;
            }
        }
        .form-result-wrapper {
            color: $white;
        }
    }
}